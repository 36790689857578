import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { graphql } from 'gatsby'
import { connect } from 'react-redux'
import { parse } from 'query-string'
import { navigate } from '@reach/router'
import propTypes from 'prop-types'

import Layout from '../../layouts/default'
import Banner from '../../components/sections/_banner/default'

import Heading from '../../components/app-components/dashed-heading'
import ChangePasswordForm from '../../components/app-components/change-password-form'
import Icon from '../../components/app-components/icon'

import './styles.scss'
import {
  doChangePassword,
  doCheckPasswordToken,
  doSetPassword,
} from '../../store/authentication/actions'
import Link from '../../components/app-components/link'
import { getUser } from '../../store/authentication/selectors'

const PasswordTemplate = ({
  location,
  user,
  changePassword,
  checkToken,
  setPassword,
  data: {
    wpPage: { seo },
  },
}) => {
  const [success, setSuccess] = useState(false)
  // get url params if set (or null)
  const { id, token } = parse(location.search)
  // redirect if not logged or resetPassword
  if (user === null) {
    if (id === null || token === null) navigate('/connexion')
    else {
      // check if token valid
      checkToken(id, token).then(res => {
        if (!res) navigate('/404')
      })
    }
  }

  const submit = data => {
    let res = false
    if (user !== null) {
      // first login scenari
      res = changePassword(data)
    } else {
      // reset password scenari
      res = setPassword(id, data.password)
    }
    setSuccess(res)
    return res
  }
  const showDescription = () =>
    id === null && token === null ? (
      <p className="pt-3">
        <strong>Personnalisez </strong>votre mot de passe ici
      </p>
    ) : (
      <p className="pt-3">
        <strong>Saisissez </strong>votre nouveau mot de passe ici
      </p>
    )
  return (
    <Layout seo={seo}>
      <Banner
        title={user !== null ? user.companyname : ''}
        img="default"
        bannerClass="login-banner"
      />
      <Container className="first-attempt-set-password-page py-5">
        <Row>
          <Col>
            <Heading
              className="first-attempt-password-page-title"
              title={
                id === null && token === null
                  ? 'Mot de passe'
                  : 'Changement de mot de passe'
              }
            />
          </Col>
        </Row>
        <Row className="text-center">
          <Col>
            <Icon name="icon-password" iconClass="login-wrapper" paths={10} />
            {success ? (
              <p className="form-dec pt-2 pb-4">
                Votre demande de <strong>changement de mot de passe</strong> a
                été prise en compte.
              </p>
            ) : (
              showDescription()
            )}
          </Col>
        </Row>
        {!success ? (
          <Row>
            <Col lg={{ span: 10, offset: 1 }}>
              <ChangePasswordForm handleSubmit={submit} />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col className="text-center">
              {id === null && token === null ? (
                <Link className="btn btn-lg btn-secondary" to="/compte/projets">
                  Retour à mes projets
                </Link>
              ) : (
                <Link className="btn btn-lg btn-secondary" to="/connexion">
                  Accéder à mon compte
                </Link>
              )}
            </Col>
          </Row>
        )}
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query PasswordPage($id: String!) {
    wpPage(id: { eq: $id }) {
      ...yoastMeta
    }
  }
`

PasswordTemplate.propTypes = {
  user: propTypes.objectOf(propTypes.any),
  changePassword: propTypes.func.isRequired,
  setPassword: propTypes.func.isRequired,
  checkToken: propTypes.func.isRequired,
  location: propTypes.shape({ search: propTypes.string.isRequired }).isRequired,
  data: propTypes.objectOf(propTypes.any).isRequired,
}
PasswordTemplate.defaultProps = {
  user: null,
}

const mapStateToProps = state => ({
  user: getUser(state),
})
const mapDispatchToProps = dispatch => ({
  changePassword: data => dispatch(doChangePassword(data)),
  checkToken: (email, token) => dispatch(doCheckPasswordToken(email, token)),
  setPassword: (email, password) => dispatch(doSetPassword(email, password)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PasswordTemplate)
