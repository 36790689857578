import React, { useState } from 'react'
import { Form, Container, Row, Col, Button } from 'react-bootstrap'
import useForm from 'react-hook-form'
import * as yup from 'yup'
import propTypes from 'prop-types'

import ErrorMessage from '../form-error-message'
import './styles.scss'

const SetPasswordForm = ({ handleSubmit }) => {
  const [form, setForm] = useState({ password: '', confirmPassword: '' })
  const { password, confirmPassword } = form
  const formSchema = yup.object().shape({
    password: yup
      .string()
      .min(8)
      .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).+$/)
      .required()
      .max(200),
    confirmPassword: yup
      .mixed()
      .oneOf([yup.ref('password'), null])
      .required(),
  })
  const {
    register,
    errors,
    formState,
    setValue,
    handleSubmit: wrapSubmit,
  } = useForm({
    validationSchema: formSchema,
    mode: 'onChange',
  })

  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisiblity = () => {
    setShowPassword(!showPassword)
  }

  const handleChange = ({ target: { value, name } }) => {
    // validate form on input change
    setValue(name, value, true)
    setForm({ ...form, [name]: value })
  }

  return (
    <Container fluid className="change-password-form">
      <Row className="justify-content-center">
        <Col md={6} sm={6}>
          <Form
            onSubmit={e => {
              e.preventDefault()
              wrapSubmit(handleSubmit(form))
            }}
          >
            <Form.Group className="form-label-group" controlId="password">
              <Form.Control
                type={showPassword ? 'text' : 'password'}
                name="password"
                ref={register}
                defaultValue={password}
                isInvalid={typeof errors.password !== 'undefined'}
                placeholder="Nouveau mot de passe"
                onChange={handleChange}
              />
              <Form.Label>Nouveau mot de passe</Form.Label>
              <span
                onClick={togglePasswordVisiblity}
                className={`password-toggle ${
                  showPassword ? 'icon-booke-hide' : 'icon-booke-show'
                }`}
              />
              <ErrorMessage name="password" errors={errors} />
            </Form.Group>

            <Form.Group
              className="form-label-group"
              controlId="confirmPassword"
            >
              <Form.Control
                type={showPassword ? 'text' : 'password'}
                name="confirmPassword"
                ref={register}
                defaultValue={confirmPassword}
                isInvalid={typeof errors.confirmPassword !== 'undefined'}
                placeholder="Confirmez votre mot de passe"
                onChange={handleChange}
              />
              <Form.Label>Confirmez votre mot de passe</Form.Label>
              <ErrorMessage name="confirmPassword" errors={errors} />
            </Form.Group>
            <div className="text-center">
              <Button
                className="mt-4"
                disabled={!formState.dirty || !formState.isValid}
                type="submit"
                onClick={() => wrapSubmit(handleSubmit(form))}
                size="lg"
                variant={
                  formState.dirty && formState.isValid
                    ? 'secondary'
                    : 'disabled'
                }
              >
                VALIDER
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}
SetPasswordForm.propTypes = {
  handleSubmit: propTypes.func.isRequired,
}
export default SetPasswordForm
